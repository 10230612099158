.partner-program {
  .main-card {
    padding: 14px 8px;

    &.referred-user {
      .stats {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #e5e5e5;

        .ant-row > *:not(:last-child) {
          border-right: 1px solid #e5e5e5;
        }

        .card {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            height: 20px;
            width: 20px;
          }

          .title {
            text-align: center;
          }
        }
      }

      .earnings-chart {
        .menu {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    &.past-transaction {
      .filter-menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 6px;
        margin-bottom: 14px;
      }

      .table {
        height: 450px;
      }
    }

    &.settlement-history {
      .total-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        margin-bottom: 8px;
      }

      .pay-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }

      .table {
        height: 450px;
      }
    }
  }
}
