.ticket_details_wrapper-details-row_4-attachments-value {
  display: flex;
  gap: 32px;
  align-items: center;
  .ticket_attachment {
    margin-top: calc(@space * 1.6);
  }
}
.breadcrumb {
  padding-top: calc(@space * 1.6);
}
.ticket-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin: 0.8rem 0;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  &-go_back {
    display: inline-block;
    cursor: pointer;
    font-size: @fontSize-large-1;
    color: #000000;
    align-items: center;
    display: flex;
    margin-right: calc(@space * 0.8);
    transition: transform 0.2s ease;
    &:hover {
      transform: translateX(-2px);
    }
  }
}
.ticket_details_wrapper {
  &-details {
    width: 100%;
    font-size: @fontSize-large-1;
    width: 100%;
    background-color: #ffffff;
    border-radius: calc(@space * 0.8);
    box-shadow: @shadow-primary;
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0;
    .details_header_text {
      font-weight: 550;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      font-size: @fontSize-large-1-5;
      padding: 0 0 calc(@space * 0.8) calc(@space * 0.8);
      margin: 0 calc(@space * 3.2);
      color: black;
      font-weight: 500;
      border-bottom: 1px solid #e5e5e5;
    }
    &-row_1,
    &-row_2,
    &-row_3 {
      margin: 0 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: calc(@space * 1.6) calc(@space * 0.8);
      gap: 2rem;
      > div {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        align-items: center;
        width: 40%;
        &:nth-child(odd) {
          border-right: 1px solid #e5e5e5;
        }
      }
    }

    // Description row
    &-row_4 {
      padding: calc(@space * 1.6) calc(@space * 0.8);
      margin: 0 calc(@space * 3.2);
      &-description-value {
        width: 100%;
        .description-text-area {
          padding: calc(@space * 1.6);
          margin-top: calc(@space * 0.8);
          width: 100%;
          height: 200px;
          max-height: 200px;
          background-color: #ffffff;
          border: 1px solid #e5e5e5;
          border-radius: 4px;
        }
      }
    }

    // attachments
    &-row_5 {
      padding: calc(@space * 1.6) calc(@space * 0.8);
      margin: 0 calc(@space * 3.2);
    }
  }
}

// Comments Table
.ticket_comments_wrapper {
  padding: calc(@space * 1.6) 0 calc(@space * 3.2);
}

// Comment from admin
.ticket_comment {
  &-header {
    &-user_name {
      padding-left: calc(@space * 0.8);
      font-size: @fontSize-large-1;
      font-weight: 500;
      color: #126abc;
    }
    &-time {
      padding-left: calc(@space * 0.8);
      color: #000000;
      opacity: 70%;
    }
  }
  &-value {
    font-size: @fontSize-large-1;
  }
}

// Ticket show tag

.ticket-show-tag {
  cursor: pointer;
  transition: 0.1s ease-in-out;
  background-color: #e5e5e5;
  border-radius: 24px;
  padding: calc(@space * 0.2) calc(@space * 0.5);
  &:hover {
    color: @blue-link-type;
  }
}
