.dashboard_container {
  // height: 18%;
  h1 {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    margin-bottom: calc(@space * 2.4);
    padding: calc(@space * 1.6) 0;
    border-bottom: 1px solid @gray-light-1;
  }
  &-navs {
    display: flex;
    justify-content: space-between;
    &-nav {
      width: 16%;
      font-weight: 600;
      padding: calc(@space * 0.8) calc(@space * 1.6);
      border-radius: 8px;
      box-shadow: @shadow-primary;
      background-color: @white-default;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &-icon {
        font-size: 1.5rem;
        width: 50px !important;
        height: 50px !important;
        border-radius: 50%;
        background-color: rgb(18, 106, 188);
        display: flex;
        align-items: center;
        justify-content: center;
        color: @white-default;
      }
      &-text {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: start;
        padding-left: calc(@space * 2.4);
        &-header {
          font-weight: 400;
          color: rgb(109, 109, 109);
          font-size: @fontSize-large-1;
        }
        &-value {
          font-weight: 700;
          font-size: @fontSize-large-2;
          display: flex;
          justify-content: center;
          color: black;
        }
      }
    }
  }
}

.subscribers-container {
  margin-top: 1.5rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  &-box {
    display: flex;
    box-shadow: @shadow-primary;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 0 0 8px 0;
    font-size: 1.4rem;
    &:nth-child(1) {
      background-color: @dash-pink;
      color: @white-default;
    }
    &:nth-child(2) {
      background-color: @dash-purple;
      color: @white-default;
    }
    &:nth-child(3) {
      background-color: @dash-orange;
      color: @white-default;
    }
    &:nth-child(4) {
      background-color: @dash-green;
      color: @white-default;
    }
    &:nth-child(5) {
      background-color: @dash-blue;
      color: @white-default;
    }
    &-text {
      &-value {
        font-size: @fontSize-large-4;
        text-align: center;
        font-weight: 700;
      }
      &-header {
        font-size: @fontSize-large-1;
      }
    }
  }
}

.table_container {
  display: flex;
  gap: 1rem;
  // height: 580px;
  &-table {
    height: 100%;
    width: 70%;
    border-radius: 0.8rem;
  }
  &-details {
    width: 30%;
    padding-bottom: calc(@space * 1.6);
    // height: 100%;
    &-title {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      border-bottom: 1px solid @gray-light-1;
      padding: calc(@space * 1.6) 0;
    }
    &-table {
      box-shadow: @shadow-primary;
      margin-top: calc(@space * 1.6);
      background-color: white;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding: 0 calc(@space * 1.6);
      min-height: 472px;
      // height: 100%;
      &-column {
        display: flex;
        justify-content: space-between;
        padding: 0.7rem 0;
        &:nth-child(5) {
          border-bottom: 1px solid @gray-light-1;
        }
      }
    }
  }
}

.subscribers_chart_container {
  margin-top: calc(@space * 1.6);
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(@space * 0.8);
    &-title {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }
  }
}
