@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");

// font
@font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// base variables
@border-radius-base: 6px;

// colors
@primary-color: #2961f0;
@error-color: #fa541c;
@text-color: #626262;

@body-background: #fff;
@layout-body-background: #fff;

@page-header-padding: 0px;
// sider
@layout-sider-background: #ffffff;
@menu-bg: transparent;
@menu-item-color: #666666;
@menu-highlight-color: #2961f0;
@menu-inline-submenu-bg: transparent;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

@menu-inline-toplevel-item-height: 30px;
@menu-item-height: 30px;

//form
@label-color: @text-color;

.ant-layout-sider {
  height: 100vh;
  border-right: 1px solid #e5e5e5;
  max-width: 240px !important;
  width: 240px !important;
  flex: none !important;
  position: sticky !important;
  top: 0 !important;
  &-collapsed {
    max-width: 80px !important;
    .ant-layout-sider-trigger {
      max-width: 80px !important;
    }
  }
  &-trigger {
    width: 240px !important;
    height: 6vh;
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    padding: 0 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: ease-in-out;
  }
  &-children {
    height: 94vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 3px !important;
      padding: 2px;
      &-thumb {
        background-color: #8b8b8b;
        border-radius: 8px;
        color: #8b8b8b;
      }
      &-track {
        padding: 2px;
        margin: 2px;
      }
    }
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  // border-radius: @border-radius-base;
  &-heading {
    background: transparent;
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 0;
  }
  &-content {
    background-color: #ffffff;
    box-shadow: @shadow-primary;
    margin-top: 1rem;
    padding: 8px 8px 0 8px;
    border-radius: 8px;
  }
}

// for all tables
.ant-page-header.has-breadcrumb {
  padding: 0px;
  background: transparent;
}

.ant-breadcrumb ol {
  padding-top: 16px;
}

// table header
.ant-table-thead > tr > th {
  background-color: #ffffff;
}
.ant-table-content {
  overflow: auto !important;
}

.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
